.escrowName {
  /* position: relative; */
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-size: 28px;
  font-weight: 700;
}

.placeholder {
  background-color: #2348ab;
  color: white;
  border-radius: 12px;
  border: 1px solid #2348ab;
}

.escrowStackPrivate {
  margin: 0;
  align-self: stretch;
  /* position: relative; */
  font-size: inherit;
  letter-spacing: 0.4px;
  font-weight: 550;
  font-family: var(--font-sarabun);
  color: #4c4e64;
}
.companyAddress {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-13xl);
}
.requestedBy {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.requestedByLabel {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.requestedByLabelParent {
  width: 10.438rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.digitalTechnologiesPrivate {
  flex: 1;
  /* position: relative; */
  letter-spacing: 0.4px;
  display: inline-block;
  min-width: 7.313rem;
}
.frameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.expiry {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.detailValues {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.emptyCells {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.detailsItems {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.signatureTypes {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
}
.requested {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.requestedWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div1 {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.frameDiv {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.div2 {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
}
.detailsHeaders1 {
  width: 16.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.signDetails {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.875rem;
  font-weight: 600;
}
.signDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-xl);
}
.nareshKumar {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.nareshKumarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2.5rem 0 0;
}
.div3 {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.frameParent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.signedWithAadhaar {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
}
.detailsHeaders2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}
.shivakumar {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.shivakumarWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div4 {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.frameParent2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.signedWithAadhaar1 {
  /* position: relative; */
  letter-spacing: 0.4px;
  line-height: 0.688rem;
}
.detailsHeaders3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}
.detailsContent,
.detailsContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  max-height: 49px;
}
.detailsContent {
  align-self: stretch;
  gap: var(--gap-13xl);
}
.scrollableContainer {
  max-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc transparent; /* Custom scrollbar styling */
}

/* For Webkit-based browsers (Chrome, Safari) */
.scrollableContainer::-webkit-scrollbar {
  width: 6px;
}

.scrollableContainer::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}

.detailsContentWrapper {
  flex: 1;
  box-shadow: 4 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  background-color: var(--color-white);
  padding: var(--padding-base) var(--padding-base) 22.062rem;
  box-sizing: border-box;
  /* min-width: 16.75rem; */
  min-height: 49.188rem;
  box-shadow: 30px 4px 10px rgba(0, 0, 0, 0.1);
}
.icon {
  height: 60px;
  /* width: 60px; */
  padding: 16px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-right: 10px;
  background-color: var(--color-white);
  font-weight: 600;
  color: var(--color-dimgray);
}
.detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #4c4e64;
}
.containAll {
  display: flex;
}
.detailsinfo {
  line-height: 2;
}
.maindiv {
  width: 100%;
  /* margin-top: 50px; */
  padding: 20px;
}
.contain {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  /* width: 16.813rem; */
  background: none;
  background-color: transparent;
  border: none;
}
.detailsHeaders {
  /* width: 16.813rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* gap: var(--gap-base); */
  /* margin-bottom: 20px; */
  border-radius: 20px;
  background-color: #f1f4ff;
  padding: 0 0.5rem;
}
.rightinfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.continuebtn {
  width: 16.813rem;
  height: 40px;
  padding: 0 0.5rem;
  color: white;
  border: none;
  background-color: var(--color-darkslateblue);
  border-radius: 10px;
  cursor: pointer;
}
.cursorIcon {
  cursor: pointer;
}
.arrowNarrowLeftIcon {
  width: 1.5rem;
  /* position: relative; */
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.back {
  /* position: relative; */
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
}

.signatureList {
  max-height: 500px;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc transparent; /* Custom scrollbar styling */
}

/* For Webkit-based browsers (Chrome, Safari) */
.signatureList::-webkit-scrollbar {
  width: 6px;
}

.signatureList::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 3px;
}
