.container {
  height: 100%;
  width: 100%;
  /* margin-left: 20px; */
  /* margin-bottom: 32px; */
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.5; /* Optional: reduce opacity to visually indicate that it is disabled */
}

.button {
  background-color: transparent;
  border: 1px solid var(--color-darkslateblue);
  padding: 10px;
  border-radius: 12px;
  color: var(--color-darkslateblue);
  font-weight: 800;
  font-family: var(--font-sarabun);
  cursor: pointer;
}
.btncontainer {
  width: 100%;
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 200px;
  left: 0; /* Use `left` instead of `inset-inline-start` for better browser compatibility */
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px; /* Adjust this if needed */
}
