.date {
  position: absolute;
  top: 16.875rem;
  left: -4.125rem;
}
.arrowNarrowLeftIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.back {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
  color: var(--color-dimgray);
  text-align: left;
}
.arrowNarrowLeftParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.escrowstackPrivateLimited {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}
.directorKyc1 {
  margin: 0;
  font-family: inherit;
  font-size: 24px;
  padding-left: var(--padding-24xl);
}
.directorKyc,
.input {
  position: relative;
  line-height: 1.5rem;
}
.directorKyc {
  flex: 1;
  letter-spacing: 0.15px;
  font-weight: 600;
}
.input {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: var(--font-roboto);
  color: var(--color-dimgray);
  text-align: right;

  justify-content: flex-end;
  display: inline-block;
}
.remove {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: var(--font-roboto);
  color: crimson;
  text-align: right;
  justify-content: flex-end;
  display: inline-block;
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.directorKycParent {
  flex: 1;
  gap: var(--gap-5xs);
}
.directorKycParent,
.frameWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.field,
.mobileMenu {
  border: 0;
  background-color: transparent;
  font-family: var(--font-roboto);
  font-size: var(--font-size-base);
  color: var(--m3-sys-light-on-surface);
}
.mobileMenu {
  flex: 1;
  position: relative;
}
.field {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mobileMenu1 {
  flex: 1;
  position: relative;
  height: 3.5rem;
}
.mobileMenuParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-start;
  gap: var(--gap-base);
}
.mobileMenu2 {
  border: 1px solid var(--m3-sys-light-outline);
  background-color: transparent;
  font-family: var(--font-roboto);
  outline: 0;
  align-self: stretch;
  position: relative;
  border-radius: var(--br-xs);
  box-sizing: border-box;
  height: 6.375rem;
  padding: var(--padding-base);
  color: var(--m3-sys-light-outline);
}
.frameContainer,
.frameDiv {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.framehead {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
}
.frameContainer {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  padding: var(--padding-base);
}
.addDirector {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: var(--font-sarabun);
  color: var(--color-darkslateblue);
  text-align: left;
}
.loadsButtons {
  cursor: pointer;
  border: 1px solid var(--color-darkslateblue);
  padding: var(--padding-5xs);
  background-color: transparent;
  flex: 0.95;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}
.generateRequest {
  position: relative;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: var(--font-sarabun);
  color: var(--color-white);
  text-align: left;
}
.loadsButtons1,
.loadsButtonsWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.loadsButtons1 {
  border-radius: var(--br-5xs);
  background-color: var(--color-darkslateblue);
  height: 2.5rem;
  justify-content: center;
  padding: var(--padding-5xs);
  box-sizing: border-box;
}
.loadsButtonsWrapper {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  justify-content: flex-start;
}
.frameGroup,
.frameParent,
.loadsButtonsParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.loadsButtonsParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-base);
}
.frameGroup,
.frameParent {
  flex-direction: column;
  gap: var(--gap-13xl);
}
.frameGroup {
  /* width: 46rem; */
  width: 100%;
  font-size: var(--font-size-13xl);
  font-family: var(--font-source-sans-3);
}
.frameParent {
  position: absolute;
  top: 0;
  left: 1.569rem;
  font-size: var(--font-size-9xl);
  color: var(--color-dimgray);
  font-family: var(--font-sarabun);
}
.pageContainer {
  position: relative;
  /* overflow: hidden; */
  text-align: left;
  font-size: var(--table-heading-size);
  color: var(--color);
  font-family: var(--table-heading);
}
.pageContainer,
.pageContainer:hover {
  background-color: var(--color-white);
  display: flex;
  align-self: stretch;
  width: auto;
  flex: 1;
  gap: var(--gap-0);
}
.mobileMenu3 {
  align-self: stretch;
  position: relative;
  /* height: 6.375rem; */
}

.field1 {
  border: 1px solid var(--m3-sys-light-outline);
  outline: 0;
  font-family: var(--font-roboto);
  font-size: 16px;
  background-color: transparent;
  position: relative;
  height: 6.375rem;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  box-sizing: border-box;
  color: var(--m3-sys-light-outline);
  padding: 20px;
}
