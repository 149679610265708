.selectActivityContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(138, 138, 138, 0.15);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.selectActivity,
.selectActivity2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.selectActivity2 {
  width: 23.75rem;
  justify-content: flex-start;
}
.xCircle {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 1.5rem;
  position: relative;
  height: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.selectActivity1 {
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 700;
  margin-bottom: 20px;
}
.selectActivityParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15px;
  gap: var(--gap-180xl);
}
.selectActivity {
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  justify-content: center;
  padding: var(--padding-13xl);
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslateblue);
  font-family: var(--font-sarabun);
  max-height: 50rem;
  max-width: 26rem;
  border-radius: 24px;
}

.kycListWrapperbtn1 {
  height: 64px;
  width: 350px;
  padding: 0 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 0.5px solid #747582;
  cursor: pointer;
  color: #747582;
}

.kycListWrapperbtn21 {
  height: 64px;
  width: 350px;
  padding: 0 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 0.5px solid #747582;
  cursor: pointer;
  color: #747582;
}
.kycListWrapperbtn3 {
  height: 64px;
  width: 350px;
  padding: 0 30px;
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 0.5px solid #747582;
  cursor: pointer;
  color: #747582;
  /* border-radius: 12px; */
}
.kycListWrapperbtn1:hover {
  background-color: #2348ab;
  color: white;
  border-radius: 3px;
  font-weight: 700;
}
.kycListWrapperbtn21:hover {
  background-color: #2348ab;
  border-radius: 3px;
  color: white;
  font-weight: 700;
  /* border: 5px solid darkolivegreen; */
}

.kycListWrapperbtn3:hover {
  background-color: #2348ab;
  border-radius: 3px;
  color: white;
  font-weight: 700;
}

.kycListWrapperbtn3:hover .scanIcon {
  content: url("../../../../public/scan.svg"); /* Changes the image source */
}

.scanHoverIcon {
  display: none; /* Hidden by default */
  margin-left: 10px; /* Adjust spacing as needed */
  width: 20px; /* Adjust size as needed */
  height: 20px;
}

.kycList11 {
  margin-left: 20px;
  font-family: var(--font-sarabun);
  letter-spacing: 0.15px;
  font-size: 20px;
}
.kycList12 {
  margin-left: 20px;
  font-family: var(--font-sarabun);
  letter-spacing: 0.15px;
  font-size: 20px;
}
.kycList13 {
  margin-left: 20px;
  font-family: var(--font-sarabun);
  letter-spacing: 0.15px;
  font-size: 20px;
}
