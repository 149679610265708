.compName {
  font-weight: 400;
  font-size: 20px;
}
.escrowName {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-size: 28px;
  font-weight: 700;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.5; /* Optional: reduce opacity to visually indicate that it is disabled */
}

.date {
  position: absolute;
  top: 16.875rem;
  left: -4.125rem;
}
.arrowNarrowLeftIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.back {
  position: relative;
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: row;
}
.arrowNarrowLeftParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.testing {
  display: block;
  flex-direction: row;
  border: 1px solid red;
}
.status {
  width: 100%;
  height: 80px;
  font-family: var(--font-sarabun);
  border: 2px dashed red;
  background-color: #fff1f3;
  display: flex;
  color: red;
  font-weight: 500;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.statusapproved {
  width: 100%;
  height: 60px;
  border: 2px dashed green;
  font-family: var(--font-sarabun);
  background-color: #f1fff8;
  display: flex;
  font-weight: 500;
  color: green;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}
.statusHeading {
  font-size: 24px;
}
.directorKycDetails {
  align-self: stretch;
}
.directorKycDetails,
.directorName {
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 600;
}
.directorNameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.div {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.4px;
  line-height: 2.688rem;
  font-weight: 600;
}
.frameContainer,
.parent {
  display: flex;
  justify-content: flex-start;
}
.parent {
  width: 0.313rem;
  flex-direction: column;
  align-items: flex-start;
  /* gap: var(--gap-13xl); */
}
.frameContainer {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xl);
}
.directorKycDetailsParent,
.frameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: var(--m3-body-large-size);
}
.directorKycDetailsParent {
  align-items: flex-start;
  gap: var(--gap-13xl);
  font-size: var(--font-size-13xl);
}
.digilockerData {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-size: 16px;
  color: black;
  /* border: 1px solid red; */
}
.digilockerData1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-size: 16px;
  /* border: 1px solid red; */
}
.digilockerDataWrapper,
.idNumberWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.digilockerDataWrapper {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  border-bottom: 1px solid var(--color-gray-200);
  padding: var(--padding-xs) var(--padding-base);
}
.idNumberWrapper {
  width: 9.125rem;
}
.div2 {
  width: 0.313rem;
  position: relative;
  letter-spacing: 0.4px;
  line-height: 2.688rem;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.frameParent4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.soVijayan6rajajiStreetb {
  width: 28.813rem;
  position: relative;
  letter-spacing: 0.4px;
  display: inline-block;
  flex-shrink: 0;
}
.frameParent16,
.frameParent3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent16 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-base);
  margin-top: 10px;
}
.frameParent3 {
  width: 41.438rem;
  flex-direction: column;
  /* gap: var(--gap-13xl); */
}
.download,
.image2Icon {
  align-self: stretch;
  position: relative;
}
.image2Icon {
  max-width: 100%;
  overflow: hidden;
  height: 15.894rem;
  flex-shrink: 0;
  object-fit: cover;
}
.download {
  font-size: var(--m3-body-large-size);
  text-decoration: underline;
  letter-spacing: 0.4px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.eAadhaarParent {
  width: 11.25rem;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--font-size-xl);
}
.eAadhaarParent,
.frameParent2,
.frameWrapper1 {
  display: flex;
  justify-content: flex-start;
}
.frameParent2 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-13xl);
}
.frameWrapper1 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.image3Icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 14.419rem;
  flex-shrink: 0;
  object-fit: cover;
}
.panParent {
  width: 10.188rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--font-size-xl);
}
.frameDiv,
.frameParent1,
.frameParent20 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent20 {
  flex-direction: row;
  gap: var(--gap-13xl);
}
.frameDiv,
.frameParent1 {
  flex-direction: column;
}
.frameParent1 {
  width: 54.688rem;
  gap: var(--gap-13xl);
  font-size: var(--m3-body-large-size);
}
.frameDiv {
  align-self: stretch;
  gap: var(--gap-xl);
  /* border: 7px solid green; */
}
.frameDiv1 {
  /* border: 2px solid maroon; */
}
.otp858254Wrapper,
.videoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.videoWrapper {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  border-bottom: 1px solid var(--color-whitesmoke);
  justify-content: flex-start;
  padding-left: 2rem;
  padding: var(--padding-xs) var(--padding-base);
  border-bottom: 1px solid var(--color-gray-200);
}
.otp858254Wrapper {
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-black);
  justify-content: center;
  padding: var(--padding-base);
}
.kia2408271414069573mlthmsokulqIcon {
  width: 7.438rem;
  position: relative;
  height: 9.919rem;
  object-fit: cover;
}
.frameParent38,
.playParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.playParent {
  justify-content: flex-start;
  /* gap: var(--gap-xl); */
}
.frameParent38 {
  align-self: stretch;
  justify-content: space-between;
}
.frameChild {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.frameParent36 {
  width: 28.375rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* padding: var(--padding-13xl); */
  box-sizing: border-box;
  /* gap: var(--gap-13xl); */
}
.reject {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--color-crimson);
  justify-content: center;
  padding: var(--padding-5xs);
  margin-right: 16px;
  cursor: pointer;
  min-width: 225px;
}
.approve,
.reject,
.rejectParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.approve {
  cursor: pointer;
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--color-seagreen);
  justify-content: center;
  padding: var(--padding-5xs);
  min-width: 225px;
}
.rejectParent {
}
.buttonParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frameGroup,
.frameParent,
.frameParent35 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameParent35 {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-xl);
  font-size: var(--m3-body-large-size);
  /* border: 3px solid yellow; */
}
.frameGroup,
.frameParent {
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.frameGroup {
  align-self: stretch;
  font-size: var(--font-size-5xl);
}
.frameParent {
  /* position: absolute;
  top: 0.5rem;
  left: 1.006rem; */
  /* width: 57.75rem; */
  width: 100%;
  font-size: var(--m3-body-large-size);
  color: var(--color-dimgray);
  font-family: var(--font-sarabun);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  /* border: 1px solid red; */
}
.pageContainer {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--color-white);
  /* overflow: hidden; */
  text-align: left;
  font-size: var(--table-heading-size);
  color: var(--color);
  font-family: var(--table-heading);
}
.videodatadigiio {
  margin-right: 25.5rem;
}
.digilockerdatavideoAadhar {
  display: flex;
  /* border: 1px solid red; */
}

.family {
  font-family: Sarabun;
  height: 100vh;
  padding: 20px;
}
