.signature-box {
  position: absolute;
  top: 100px; /* Adjust the initial position as needed */
  left: 100px; /* Adjust the initial position as needed */
  width: 200px;
  height: 60px;
  background-color: rgb(230, 229, 236);
  border: 1px solid rgb(65, 42, 137);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.imageContainer {
}
.editable-box {
  flex: 1;
  text-align: center;
  border: 1px solid #ccc;
  padding: 5px;
  background-color: #fff;
}

.dropdown-icon {
  margin-left: 8px;
}

.expandable-corners {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.expand-top-right,
.expand-top-left,
.expand-bottom-right,
.expand-bottom-left {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.expand-top-right {
  top: 0;
  right: 0;
}

.expand-top-left {
  top: 0;
  left: 0;
}

.expand-bottom-right {
  bottom: 0;
  right: 0;
}

.expand-bottom-left {
  bottom: 0;
  left: 0;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}
