.areYouSure {
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 500;
}
.boxArrowInDownIcon {
  width: 1.125rem;
  position: relative;
  height: 1.125rem;
  display: none;
}
.cancel1 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}
.cancel {
  flex: 1;
  border-radius: var(--br-5xs);
  border: 1px solid var(--color-darkslateblue);
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-5xs);
  cursor: pointer;
}
.cancel,
.cancelParent,
.create {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.create {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--color-darkslateblue);
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-base);
  color: var(--color-white);
  cursor: pointer;
}
.cancelParent {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.addCompany,
.addCompanyInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.addCompanyInner {
  width: 31.625rem;
  align-items: flex-start;
  font-size: var(--m3-body-large-size);
}
.addCompany {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 240, 240, 0.8); /* Light gray with transparency */
}
.addCompany1 {
  width: 624px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  align-items: center;
  padding: var(--padding-13xl);
  gap: var(--gap-29xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslateblue);
  font-family: var(--font-sarabun);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
