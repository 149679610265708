.cursorIcon {
  cursor: pointer;
}

.escrowName {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-size: 28px;
  font-weight: 700;
}

.pageContainer {
  padding: 20px;
}

.custom-dropdown {
  width: 350px;
}

.custom-dropdown option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; /* Does not work in native <option> */
}
