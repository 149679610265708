.disabledDiv {
  pointer-events: none;
  opacity: 0.5; /* Optional: reduce opacity to visually indicate that it is disabled */
}
.tableBg {
  background-color: #f3f4f7;
}
.date {
  /* position: absolute; */
  top: 270px;
  left: -66px;
}
.escrowstackPrivateLimited {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
  font-size: 20px;
}
.plusCircleIcon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.newKycRequest {
  position: relative;
  font-size: var(--m3-body-large-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--font-sarabun);
  color: var(--color-white);
  text-align: left;
}
.escrowstackPrivateLimitedParent,
.loadsButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.loadsButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: var(--color-darkslateblue);
  border-radius: var(--br-5xs);
  height: 40px;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.escrowstackPrivateLimitedParent {
  align-self: stretch;
  /* gap: var(--gap-553xl); */
  font-size: var(--font-size-9xl);
  justify-content: space-between;
}
.leadingIcon {
  width: 24px;
  position: relative;
  height: 24px;
  margin-top: 15px;
  margin-right: 10px;
}
.labelText {
  position: relative;
  letter-spacing: 0.5px;
}
.leadingIconParent {
  width: 245px;
  border-bottom: 1px solid var(--m3-sys-light-outline);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-107xl) var(--padding-9xs) var(--padding-5xs);
  gap: var(--gap-7xs);
}
.frameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.frameDiv {
  display: flex;
  flex-direction: row;
}

.frameContainer {
  flex: 1;
  justify-content: flex-end;
}
.label {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 16px;
  font-weight: 500;
}
.floatingLabel {
  position: absolute;
  top: -5px;
  left: -4px;
  background-color: var(--m3-sys-light-surface);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}
.input1 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.input {
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--m3-body-large-size);
  color: var(--m3-sys-light-on-surface);
}
.content,
.field,
.input {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
}
.content {
  width: calc(100% - 35px);
  right: 19px;
  left: 16px;
}
.field {
  width: 100%;
  right: 0;
  left: 0;
  border-radius: var(--br-xs);
  border: 1px solid var(--m3-sys-light-outline);
  box-sizing: border-box;
}
.mobileMenu {
  width: 245px;
  position: relative;
  height: 56px;
}
.input3 {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--m3-body-large-size);
  color: var(--m3-sys-light-on-surface);
}
.mobileMenuChild {
  position: absolute;
  top: calc(50% - 12px);
  right: 12px;
  width: 24px;
  height: 24px;
}
.loadsButtons1,
.mobileMenuParent {
  display: flex;
  flex-direction: row;
}
.loadsButtons1 {
  border-radius: var(--br-xs);
  background-color: var(--color-darkslateblue);
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  font-size: var(--m3-body-large-size);
  color: var(--color-white);
  font-family: var(--font-sarabun);
  cursor: pointer;
}
.mobileMenuParent {
  gap: var(--gap-base);
  color: var(--m3-sys-light-outline);
  justify-self: flex-end;
  z-index: 0;
}
.frameGroup,
.frameWrapper {
  display: flex;
  justify-content: flex-start;
}
.frameGroup {
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-45xl);
}
.frameWrapper {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-21xl);
  font-size: var(--font-size-5xl);
  color: var(--color-darkslateblue);
  align-self: flex-end;
}
.createdDateParent,
.noRecordsFoundWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.createdDateParent {
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  background-color: var(--color-gray-100);
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-13xl);
  gap: var(--gap-227xl);
}
.noRecordsFoundWrapper {
  justify-content: center;
  padding: var(--padding-181xl) var(--padding-13xl);
  font-family: var(--font-source-sans-3);
}
.rowsPerPage {
  width: 119px;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
  display: inline-block;
  height: 24px;
  flex-shrink: 0;
}
.rowsPerPageParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameChild {
  width: 60.3px;
  position: relative;
  height: 11.5px;
}
.frameWrapper1,
.of20Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.of20Parent {
  gap: var(--gap-base);
}
.frameWrapper1 {
  border-radius: 0 0 var(--br-xs) var(--br-xs);
  background-color: var(--color-gray-100);
  padding: var(--padding-base) var(--padding-13xl) var(--padding-base) var(--padding-733xl);
}
.frameParent,
.frameParent1 {
  display: flex;
  flex-direction: column;
}
.frameParent1 {
  align-self: stretch;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  align-items: center;
  justify-content: center;
}
.frameParent {
  top: 0;
  width: 100%;
  /* align-items: flex-end; */
  justify-content: flex-start;
  /* gap: var(--gap-13xl); */
  font-size: var(--m3-body-large-size);
  color: var(--color-dimgray);
  font-family: var(--font-sarabun);
}
.pageContainer {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
  text-align: left;
  font-size: var(--m3-body-large-size);
  color: var(--color);
  font-family: var(--font-sarabun);
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 16px;
}

.theadcontain {
  background-color: #f0f0f0; /* Light gray background for thead */
  text-align: left; /* Align the text to the left */
  justify-content: space-between;
  border-radius: 10px;
}

.trheaderContent th {
  padding: 16px; /* Add padding for better spacing */
  text-align: center; /* Center-align the text */
  /* font-weight:bold; */
}

.tbodycontain tr td {
  padding: 16px; /* Add padding for better spacing */
  text-align: center; /* Center-align the text */
  border-bottom: 1px solid #ddd; /* Add a bottom border for rows */
}

.table tr:hover {
  background-color: #f5f5f5; /* Add a subtle hover effect for rows */
}

.eyeIcon {
  width: 1.5rem;
  /* margin-top:2px ; */
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.btn {
  background: none;
  border: none;
  align-items: center;
  cursor: pointer;
}
.rebtn {
  background: none;
  border: none;
  align-items: flex-end;
  cursor: pointer;
}
.containerbtn {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.tableHeading {
  font-family: var(--font-sarabun);
  font-weight: 100;
}

.escrowName {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-size: 28px;
  font-weight: 700;
}
.compName {
  font-weight: 400;
  font-size: 20px;
}

.height100 {
  height: 75vh;
}
.family {
  font-family: Sarabun;
  height: 100vh;
  padding: 20px;
}
