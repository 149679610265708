.disabledDiv {
  pointer-events: none;
  opacity: 0.4; /* Optional: reduce opacity to visually indicate that it is disabled */
}

.escrowName {
  font-size: 28px;
  color: #4c4e64;
  line-height: 24px;
  font-weight: 700;
}

.date {
  position: absolute;
  top: 16.875rem;
  left: -4.125rem;
}
.arrowNarrowLeftIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.back {
  position: relative;
  letter-spacing: 0.4px;
}
.arrowNarrowLeftParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
}
.contractsDetails {
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 600;
}
.contractsDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-13xl);
}
.frameChild {
  width: 13.488rem;
  position: relative;
  height: 16.913rem;
  object-fit: cover;
}
.download {
  align-self: stretch;
  position: relative;
  text-decoration: underline;
  letter-spacing: 0.4px;
  opacity: 0;
}
.groupParent {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base);
  gap: var(--gap-base);
}
.div,
.nameOfContract {
  width: 12.5rem;
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.div {
  width: 0.313rem;
  line-height: 2.688rem;
}
.nameOfContractParent,
.statusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.nameOfContractParent {
  gap: var(--gap-base);
}
.statusWrapper {
  width: 12.5rem;
}
.aug2024 {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: var(--font-source-sans-3);
  font-size: 18px;
}
.frameParent1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: var(--gap-13xl); */
}
.viewDocument {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}
.viewDocumentWrapper {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-darkslateblue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-base);
  color: var(--color-white);
  cursor: pointer;
}
.viewDocumentWrapper1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: transparent;
  display: flex;
  border: 1px solid var(--color-darkslateblue);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-base);
  color: var(--color-darkslateblue);
  cursor: pointer;
}

.frameContainer,
.frameDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv {
  width: 24.5rem;
  flex-direction: column;
  gap: var(--gap-13xl);
  text-align: left;
}
.frameContainer {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-base);
  text-align: center;
}
.counterpartyDetails {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-weight: 600;
}
.counterpartyDetailsWrapper {
  align-self: stretch;
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  background-color: var(--color-gray-100);
  border-bottom: 1px solid var(--color-gray-200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-41xl) var(--padding-base) var(--padding-13xl);
  font-size: var(--font-size-5xl);
}
.companyNameParent,
.roleParent {
  width: 21.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.roleParent {
  width: 14.813rem;
  justify-content: flex-start;
  gap: var(--gap-151xl);
}
.nameParent {
  gap: var(--gap-157xl);
}
.frameWrapper1,
.nameParent,
.nithinescrowstackioParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameWrapper1 {
  align-self: stretch;
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  background-color: var(--color-gray-100);
  padding: var(--padding-base) var(--padding-41xl);
}
.nithinescrowstackioParent {
  gap: var(--gap-97xl);
}
.remove {
  position: relative;
  text-decoration: underline;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}
.frameWrapper2,
.nithinParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.nithinParent {
  gap: var(--gap-148xl);
}
.frameWrapper2 {
  align-self: stretch;
  border-bottom: 1px solid var(--color-whitesmoke);
  padding: var(--padding-base) var(--padding-41xl);
  font-family: var(--font-source-sans-3);
}
.paragParent,
.paragescrowstackioParent,
.pavanParent,
.pavanescrowstackioParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-107xl);
}
.paragParent,
.paragescrowstackioParent,
.pavanParent {
  gap: var(--gap-142xl);
}
.paragParent,
.paragescrowstackioParent {
  gap: var(--gap-118xl);
}
.paragParent {
  gap: var(--gap-141xl);
}
.rowsPerPage {
  width: 7.438rem;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  display: inline-block;
  height: 1.5rem;
  flex-shrink: 0;
}
.rowsPerPageParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameItem {
  width: 3.769rem;
  position: relative;
  height: 0.719rem;
}
.frameWrapper5 {
  border-radius: 0 0 var(--br-xs) var(--br-xs);
  background-color: var(--color-gray-100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-13xl) var(--padding-base) var(--padding-734xl);
}
.frameGroup,
.frameParent8 {
  align-self: stretch;
  border-radius: var(--br-xs);
}
.frameParent8 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  gap: 1rem;
}
.frameGroup,
.frameParent,
.frameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameWrapper {
  width: 100%;
}
.frameParent {
  /* position: absolute; */
  /* top: 0; */
  /* left: 1.006rem; */
  gap: var(--gap-13xl);
  font-size: var(--m3-body-large-size);
  color: var(--color-dimgray);
  font-family: var(--font-sarabun);
  margin-bottom: 32px;
}
.pageContainer {
  align-self: stretch;
  flex: 1;
  /* position: relative; */
  background-color: var(--color-white);
  overflow: hidden;
  text-align: left;
  font-size: var(--table-heading-size);
  color: var(--color);
  font-family: var(--table-heading);
}
.detailContainer {
  width: 100%;
  border-radius: 20px;
  background-color: #f2f2f2;
  /* padding: 20px; */
}

.detailHeader {
  /* background-color:  #f2f2f2; */
  padding: 20px;
  font-size: 24px;
  font-weight: 470;
  color: #4c4e64;
  /* width: 100%; */
  border-bottom: 1px solid #aaaaaa;
  /* border-radius: 20px; */
}
.table {
  padding: 40px;
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  background-color: white;
  padding: 20px;
}

.thead {
  background-color: #f2f2f2;
}

.tr {
  transition: background-color 0.3s;
}

.tr:hover {
  background-color: #e0e0e0;
}

.th,
.td {
  font-size: 16px;
  padding: 20px 20px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: #4c4e64;
}

.th {
  font-weight: bold;
}
.button {
  border: none;
  color: #4c4e64;
  background-color: transparent;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  text-align: left;
  padding: 20px 20px;
}
.pagination {
  padding: 20px;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}

.family {
  font-family: Sarabun;
  height: 100vh;
  padding: 20px;
}

.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  /* margin-top: 16px; */
}

.pageButton {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.pageButton:hover {
  background-color: #e0e0e0;
}

.activePage {
  background-color: #2348ab;
  color: #fff;
  font-weight: bold;
}

.disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}
