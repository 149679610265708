Styles for es16.png .es16Icon {
  position: relative;
  width: 100px; /* Set to the same size as the previous .escrowstackLogos28 */
  height: 100px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-181xl);
  overflow: hidden;
  flex-shrink: 0;
}

.stickySidenav {
  position: sticky;
  top: 0; /* Sticky at the top */
  height: 100vh; /* Full viewport height */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Remove all other specific image styles */
.vectorIcon,
.groupIcon,
.groupIcon1,
.groupIcon2,
.groupIcon3,
.groupIcon4,
.groupIcon5,
.groupIcon6,
.groupIcon7,
.groupIcon8,
.groupIcon9,
.groupIcon10,
.groupIcon11,
.groupIcon12,
.rocket13Icon {
  display: none; /* Hide all other images */
}

/* General Styles (if needed) */
.sidenavecontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base);
}

.building07Icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.companyList1 {
  /* flex: 1;
  position: relative;
  font-size: var(--m3-body-large-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--font-sarabun);
  color: var(--color-white);
  text-align: left; */
  flex: 1;
  position: relative;
  font-size: var(--m3-body-large-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--font-sarabun);
  color: var(--color-slategray);
  text-align: left;
  flex-shrink: 0;
}

.companyList {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs);
  background-color: transparent;
  align-self: stretch;
  border-radius: var(--br-xs);
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  margin-left: 5px;
  margin-right: 5px;
}

.companyList,
.kycList,
.kycList1 {
  display: flex;
  align-items: center;
}

.kycList1 {
  /* width: 100px; */
  position: relative;
  font-size: var(--m3-body-large-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--font-sarabun);
  color: var(--color-slategray);
  text-align: left;
  flex-shrink: 0;
}

.kycList {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs);
  background-color: transparent;
  width: 156px;
  border-radius: var(--br-xs);
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.hiddendiv {
  display: none !important;
}

.agreementIcon {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.contractsList1 {
  width: 104px;
  position: relative;
  font-size: var(--m3-body-large-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--font-sarabun);
  color: var(--color-slategray);
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.contractsList {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs);
  margin-right: 4px;
  background-color: transparent;
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-decoration: none;
}

.companyListParent,
.kycListParent,
.sidenavecontainerParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.kycListParent {
  padding: 0 0 0 var(--padding-11xl);
  gap: var(--gap-base);
  margin-left: 1.4rem;
}

.companyListParent,
.sidenavecontainerParent {
  gap: var(--gap-xl);
}

.sidenavecontainerParent {
  background-color: var(--color-white);
  border-right: 1px solid var(--color-gainsboro);
  box-sizing: border-box;
  height: 100vh;
  /* padding: var(--padding-xs) var(--padding-xs) 0; */
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--font-size-2xs);
  color: var(--color-darkslateblue);
  font-family: var(--font-sarabun);
  top: 0;
  left: 0;
  position: sticky;
  max-height: 100vh;
  /* overflow-y: auto; */
}
.Active {
  background-color: var(--color-darkslateblue);
  color: var(--color-white);
  font-weight: 600;
}
.Active1 {
  background-color: var(--color-darkslateblue);
  color: var(--color-white);
  /* font-weight: bold; */
}
.logout {
  display: flex;
  align-items: flex-end;
  height: 90%;
}
.companyListParent {
  height: 90%;
  display: flex;
}
