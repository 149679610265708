.disabledDiv {
  pointer-events: none;
  opacity: 0.5;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.paginationWrapper {
  display: flex;
  justify-content: flex-end; /* Align pagination to the right */
  margin-top: 16px; /* Add spacing from the table */
}

.tableBg {
  background-color: #f3f4f7;
}

.leadingIcon {
  /* width: 24px; */
  position: relative;
  height: 24px;
  margin-top: 15px;
  margin-right: 10px;
}

.newCompany {
  cursor: pointer;
  border: none;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  color: var(--color-white);
  border-radius: var(--br-5xs);
  background-color: var(--color-darkslateblue);
}

.plusCircleIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.addNewCompany {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}

.frameGroup,
.pageContainer {
  background-color: var(--color-white);
}
.frameGroup {
  margin-top: 20px;
  /* margin-left: 20px; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  color: var(--color-dimgray);
}
.height100 {
  height: 70vh;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table tr:hover {
  background-color: #f5f5f5; /* Add a subtle hover effect for rows */
}

.theadcontain {
  background-color: #f3f4f7; /* Light gray background for thead */
  text-align: left; /* Align the text to the left */
}

.trheaderContent th {
  padding: 10px;
  text-align: center; /* Center-align the text */
  font-weight: 500; /* Make the header text bold */
  font-size: 16px;
}

.tbodycontain tr td {
  padding: 10px; /* Add padding for better spacing */
  text-align: center; /* Center-align the text */
  border-bottom: 1px solid #ddd; /* Add a bottom border for rows */
  /* height: 60vh; */
}

.leadingIconParent {
  width: 22.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-107xl) var(--padding-9xs) var(--padding-5xs);
  gap: var(--gap-7xs);
}

.rejectionModal {
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
  z-index: 1050; /* Ensure it overlays other elements */
  font-family: Sarabun;
}

/* Modal Dialog */
.modalDialog {
  max-width: 570px;
  margin: auto;
}

/* Modal Content */
.modalContent {
  border: 2px solid grey; /* Blue border */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

/* Modal Header */
.modalHeader {
  background-color: #f8f9fa;
  padding: 20px;
}

.modalTitle {
  color: #2348ab;
  font-weight: 500;
  font-size: 24px;
}

.closeButton {
  color: #007bff;
  border: none;
}

/* Modal Body */
.modalBody {
  padding: 15px;
}

.textarea {
  border: 1px solid #79747e;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  resize: none;
  min-height: 100px;
  width: 100%;
}

/* Modal Footer */
.modalFooter {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
}

/* Cancel Button */
.cancelButton {
  background-color: #f8f9fa;
  color: #2348ab;
  border: 1px solid #2348ab;
  border-radius: 5px;
  font-weight: 700;
  width: 47%;
  font-size: 16px;
}

.cancelButton:hover {
  background-color: #e9ecef;
}

.rejectButton {
  background-color: #2348ab;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  width: 47%;
  font-size: 16px;
}

.rejectButton:hover {
  background-color: #c82333;
}

.escrowName {
  font-weight: 700;
  font-size: 28px;
}

.compName {
  font-weight: 400;
  font-size: 24px;
}

.family {
  font-family: Sarabun;
  height: 100vh;
  padding: 20px;
}
