.building07Icon,
.xCircleIcon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}

.building07Icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}

.building07Icondis {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  cursor: not-allowed;
}

.building07Icon {
  width: 48px;
  height: 48px;
}

.company {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.companydis {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 24px;
  cursor: not-allowed;
}

.building07Parent {
  width: 190px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-5xs);
  cursor: pointer;
}

.building07Parentdis {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-5xs);
  cursor: not-allowed;
}

.vectorIcon {
  width: 35px;
  position: relative;
  height: 48.5px;
}

.frameParent,
.vectorParent,
.xCircleParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.vectorParent {
  width: 190px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-5xs);
  background-color: var(--color-white);
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-5xs);
  cursor: pointer;
}

.frameParent,
.xCircleParent {
  gap: var(--gap-13xl);
}

.frameParent {
  align-self: stretch;
  flex-direction: row;
}

.xCircleParent {
  /* position: absolute;
  top: 197px;
  left: 598px; */
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  flex-direction: column;
  padding: var(--padding-13xl) var(--padding-52xl);
  text-align: left;
  font-size: var(--m3-body-large-size);
  color: var(--color-darkslateblue);
  font-family: var(--font-sarabun);
  padding: 40px;
}

.modlestyle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(138, 138, 138, 0.6);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
