.date {
  position: absolute;
  top: 16.875rem;
  left: -4.125rem;
}
.escrowstackLogos27Icon {
  width: 60%;
  position: relative;
  /* border-radius: var(--br-181xl); */
  height: 6.5;
  overflow: hidden;
  flex-shrink: 0;
  /* object-fit: cover; */
}
.logo {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.pleaseSignInTo,
.welcomeToYour {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}
.welcomeToYour {
  font-weight: 600;
}
.pleaseSignInTo {
  font-size: var(--m3-body-large-size);
  color: var(--color-gray-200);
}
.welcomeToYourEscrowStackParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xl);
}
.inputoutlined {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  font-family: var(--m3-body-large);
  font-size: var(--m3-label-medium-size);
  color: var(--color-dodgerblue);
}
.boxArrowInDownIcon {
  width: 1.125rem;
  position: relative;
  height: 1.125rem;
  display: none;
}
.loads {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}
.loadsButtons {
  cursor: pointer;
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--color-darkslateblue);
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.card,
.inputoutlinedParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.inputoutlinedParent {
  align-self: stretch;
  gap: var(--gap-xl);
  font-size: var(--m3-body-large-size);
  color: var(--color-white);
  font-family: var(--font-sarabun);
}
/* .card {
  position: absolute;
  top: 11.063rem;
  left: calc(50% - 175.5px);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  padding: var(--padding-13xl);
  gap: var(--gap-13xl);
  font-size: var(--font-size-xl);
}
.pageContainer {
  align-self: stretch;
  height: 48rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  background-image: url(/public/page-container@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--table-heading-size);
  color: var(--color);
  font-family: var(--table-heading);
} */
.pageContainer {
  align-self: stretch;
  height: 100vh; /* Adjust height to fit the screen */
  position: relative;
  overflow: hidden;
  background-image: url(/public/bg_login.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--table-heading-size);
  color: var(--color);
  font-family: var(--table-heading);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add padding for smaller screens */
}

.card {
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  padding: var(--padding-13xl);
  font-size: var(--font-size-xl);
  width: 100%;
  max-width: 450px;
  box-sizing: border-box; /* Ensure padding is inside the card */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-13xl);
}

@media (max-width: 768px) {
  .card {
    padding: var(--padding-base); /* Adjust padding for medium screens */
  }

  .date {
    top: 14rem; /* Adjust top positioning */
    left: 0; /* Center the date */
    text-align: center; /* Align date to center */
    width: 100%;
  }

  .escrowstackLogos27Icon {
    width: 9rem;
    height: 4rem; /* Reduce size for smaller screens */
  }

  .inputoutlinedParent {
    gap: var(--gap-base); /* Adjust gap for smaller screens */
  }
}

@media (max-width: 480px) {
  .card {
    padding: var(--padding-xs); /* Adjust padding for small screens */
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .escrowstackLogos27Icon {
    width: 3rem;
    height: 3rem; /* Further reduce size for very small screens */
  }

  .loadsButtons {
    height: 2rem; /* Reduce button height for smaller screens */
    font-size: 14px;
  }

  .inputoutlinedParent {
    gap: var(--gap-md); /* Reduce gap further for very small screens */
  }
}

.error {
  color: red;
}
