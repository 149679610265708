.rejectionModal {
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
  z-index: 1050; /* Ensure it overlays other elements */
  font-family: Sarabun;
}

/* Modal Dialog */
.modalDialog {
  max-width: 570px;
  margin: auto;
}

/* Modal Content */
.modalContent {
  border: 2px solid grey; /* Blue border */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

/* Modal Header */
.modalHeader {
  background-color: #f8f9fa;
  padding: 20px;
}

.modalTitle {
  color: #2348ab;
  font-weight: 500;
  font-size: 24px;
}

.closeButton {
  color: #007bff;
  border: none;
}

/* Modal Body */
.modalBody {
  padding: 15px;
}

.textarea {
  border: 1px solid #79747e;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  resize: none;
  min-height: 100px;
  width: 100%;
}

/* Modal Footer */
.modalFooter {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
}

/* Cancel Button */
.cancelButton {
  background-color: #f8f9fa;
  color: #2348ab;
  border: 1px solid #2348ab;
  border-radius: 5px;
  font-weight: 700;
  width: 47%;
  font-size: 16px;
}

.cancelButton:hover {
  background-color: #e9ecef;
}

.rejectButton {
  background-color: #2348ab;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  width: 47%;
  font-size: 16px;
}

.rejectButton:hover {
  background-color: #c82333;
}
