@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-sarabun: Sarabun;
  --font-source-sans-3: "Source Sans 3";
  --m3-body-large: Roboto;
  --table-heading: Nunito;

  /* font sizes */
  --m3-body-large-size: 1rem;
  --m3-label-medium-size: 0.75rem;
  --font-size-5xl: 1.5rem;
  --table-heading-size: 1.125rem;
  --font-size-13xl: 2rem;
  --font-size-xl: 1.25rem;
  --m3-body-large-size: 1rem;
  --font-size-5xl: 1.5rem;
  --font-size-13xl: 2rem;
  --table-heading-size: 1.125rem;
  --m3-label-medium-size: 0.75rem;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #fafafa;
  --m3-sys-light-outline: #79747e;
  --m3-sys-light-on-surface: #1c1b1f;
  --color-dimgray: #4c4e64;
  --color-darkslateblue: #2348ab;
  --m3-sys-light-surface: #fffbfe;
  --m3-sys-light-on-surface-variant: #49454f;
  --color: #333;
  --color-gray-300: #9c9c9c;
  --color-ghostwhite: #f6f8ff;
  --color-gainsboro: #e6e6e6;
  --color-lightgray: #d2d2d2;
  --color-coral: #fe914d;
  --color-slategray: #747582;
  --color-crimson: #dc3545;
  --color-seagreen: #198754;
  --color-whitesmoke-100: #f9f9f9;
  --color-whitesmoke: #f5f5f5;
  --color-whitesmoke-200: #eee;
  --color-gray-200: rgba(117, 117, 117, 0.5);

  /* Gaps */
  --gap-base: 1rem;
  --gap-227xl: 15.375rem;
  --gap-21xl: 2.5rem;
  --gap-77xl: 6rem;
  --gap-5xs: 0.5rem;
  --gap-12xs: 0.062rem;
  --gap-7xs: 0.375rem;
  --gap-341xl: 22.5rem;
  --gap-xl: 1.25rem;
  --gap-xs: 0.75rem;
  --gap-13xl: 2rem;
  --gap-166xl: 11.562rem;
  --gap-29xl: 3rem;
  --gap-base: 1rem;
  --gap-5xs: 0.5rem;
  --gap-13xl: 2rem;
  --gap-141xl: 10rem;
  --gap-118xl: 8.562rem;
  --gap-142xl: 10.062rem;
  --gap-107xl: 7.875rem;
  --gap-148xl: 10.437rem;
  --gap-97xl: 7.25rem;
  --gap-157xl: 11rem;
  --gap-151xl: 10.625rem;
  --gap-341xl: 22.5rem;
  --gap-xl: 1.25rem;
  --gap-12xs: 0.062rem;
  --gap-442xl: 28.812rem;

  /* Paddings */
  --padding-base: 1rem;
  --padding-13xl: 2rem;
  --padding-733xl: 47rem;
  --padding-181xl: 12.5rem;
  --padding-9xs: 0.25rem;
  --padding-107xl: 7.875rem;
  --padding-5xs: 0.5rem;
  --padding-7xl: 1.625rem;
  --padding-xs: 0.75rem;
  --padding-857xl: 54.75rem;
  --padding-5xl: 1.5rem;
  --padding-33xl: 3.25rem;
  --padding-24xl: 2.687rem;
  --padding-14xl: 2.062rem;
  --padding-13xl: 2rem;
  --padding-5xs: 0.5rem;
  --padding-base: 1rem;
  --padding-734xl: 47.062rem;
  --padding-41xl: 3.75rem;
  --padding-7xl: 1.625rem;
  --padding-9xs: 0.25rem;

  /* Border radiuses */
  --br-xs: 12px;
  --br-5xs: 8px;
  --br-181xl: 200px;
  --br-xs: 12px;
  --br-5xs: 8px;
}

.ribbonGroupTrackedChanges {
  display: none !important;
}
