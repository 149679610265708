/* General Modal Styling */
.approvalModal {
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
  z-index: 1050; /* Ensure it overlays other elements */
  font-family: Sarabun;
}

.customModalContent {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.customModalHeader {
  background-color: #007bff;
  color: white;
}

.modalTitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.customModalBody {
  text-align: center;
  padding: 20px;
  color: #2348ab;
  font-family: Sarabun;
  font-weight: 500;
  font-size: 24px;
}

.icon {
  margin: 20px auto;
  width: 150px;
  height: 150px;
}

.customModalFooter {
  justify-content: space-between;
}

.customCancelBtn {
  background-color: #f8f9fa;
  color: #6c757d;
  border: 1px solid #6c757d;
  font-weight: bold;
  width: 47%;
}

.customCancelBtn:hover {
  background-color: #e2e6ea;
  color: #495057;
}

.customApproveBtn {
  background-color: #2348ab;
  color: white;
  font-weight: bold;
  width: 47%;
}

.customApproveBtn:hover {
  background-color: #218838;
}
