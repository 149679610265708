.textarea {
  border: 2px solid red;
  height: 100px;
  width: 300px;
  padding: 30px;
}
.contract {
  height: 100%;
  width: 100%;
  /* border: 1px solid red; */
}
