.disabledDiv {
  pointer-events: none;
  opacity: 0.5; /* Optional: reduce opacity to visually indicate that it is disabled */
}

.tableBg {
  background-color: #f3f4f7;
}

.plusCircleIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.addNewCompany {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}
.loadsButtons {
  /* position: absolute;
  left: 59.569rem; */
  /* justify-content: flex-end; */
}
.newCompany_div {
  display: flex;
  justify-content: flex-end;
}
.newCompany {
  cursor: pointer;
  background: none;
  border: none;
  /* height: 2.5rem; */
  /* width: 12.5rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  color: var(--color-white);
  border-radius: var(--br-5xs);
  background-color: var(--color-darkslateblue);
  /* max-width: 11.5rem; */
}
.searchFilters {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 500;
}
.leadingIcon {
  width: 24px;
  position: relative;
  height: 24px;
  margin-top: 15px;
  margin-right: 10px;
}
.labelText {
  position: relative;
  letter-spacing: 0.5px;
  font-size: 16px;
}
.leadingIconParent {
  width: 22.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: flex-start; */
  padding: 0 var(--padding-107xl) var(--padding-9xs) var(--padding-5xs);
  gap: var(--gap-7xs);
}
.label {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1rem;
  font-weight: 500;
}
.floatingLabel {
  position: absolute;
  top: -0.312rem;
  left: -0.25rem;
  background-color: var(--m3-sys-light-surface);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}
.input1 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
}
.cursorIcon {
  width: 0;
  position: relative;
  height: 1rem;
  display: none;
}
.input {
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--m3-body-large-size);
  color: var(--m3-sys-light-on-surface);
}
.content,
.field,
.input {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
}
.content {
  width: calc(100% - 35px);
  right: 1.188rem;
  left: 1rem;
}
.field {
  width: 100%;
  right: 0;
  left: 0;
  border-radius: var(--br-xs);
  border: 1px solid var(--m3-sys-light-outline);
  box-sizing: border-box;
}
.mobileMenu {
  width: 15.313rem;
  position: relative;
  height: 3.5rem;
}
.input3 {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  font-size: var(--m3-body-large-size);
  color: var(--m3-sys-light-on-surface);
}
.mobileMenuChild {
  position: absolute;
  top: calc(50% - 12px);
  right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
}
.boxArrowInDownIcon {
  width: 1.125rem;
  position: relative;
  height: 1.125rem;
  display: none;
}
.loadsButtons1,
.mobileMenuParent {
  flex-direction: row;
  align-items: center;
}
.loadsButtons1 {
  border-radius: var(--br-xs);
  background-color: var(--color-darkslateblue);
  display: flex;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  gap: var(--gap-5xs);
  font-size: var(--m3-body-large-size);
  color: var(--color-white);
  font-family: var(--font-sarabun);
  cursor: pointer;
}
.mobileMenuParent {
  gap: var(--gap-base);
  color: var(--m3-sys-light-outline);
  justify-self: flex-end;
  z-index: 0 !important;
}
.frameParent,
.mobileMenuParent,
.searchFiltersParent {
  display: flex;
  justify-content: flex-start;
}
.frameParent {
  flex-direction: row;
  align-items: flex-end;
  gap: var(--gap-77xl);
  width: 100%;
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-on-surface-variant);
  font-family: var(--m3-body-large);
  display: flex;
  justify-content: space-between;
}
.searchFiltersParent {
  /* margin-top: var(--gap-13xl); */
  /* margin-left: 20px; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  flex-direction: column;
  align-items: flex-start;
  /* padding: var(--padding-base); */
  /* gap: var(--gap-21xl); */
  font-size: var(--font-size-5xl);
  color: var(--color-darkslateblue);
  /* width: 98%; */
  align-self: flex-end;
}
.createdDateParent,
.noRecordsFoundWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.createdDateParent {
  border-radius: var(--br-xs) var(--br-xs) 0 0;
  background-color: var(--color-gray-100);
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-13xl);
  gap: var(--gap-227xl);
}
.noRecordsFoundWrapper {
  justify-content: center;
  padding: var(--padding-181xl) var(--padding-13xl);
  font-family: var(--font-source-sans-3);
}
.rowsPerPage {
  width: 7.438rem;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  display: inline-block;
  height: 1.5rem;
  flex-shrink: 0;
}
.rowsPerPageParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameChild {
  width: 3.769rem;
  position: relative;
  height: 0.719rem;
}
.frameContainer,
.frameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameContainer {
  gap: var(--gap-base);
}
.frameWrapper {
  border-radius: 0 0 var(--br-xs) var(--br-xs);
  background-color: var(--color-gray-100);
  padding: var(--padding-base) var(--padding-13xl) var(--padding-base) var(--padding-733xl);
}
.frameGroup,
.pageContainer {
  background-color: var(--color-white);
}
.frameGroup {
  /* margin-top: 20px; */
  width: 100%;
  /* margin-left: 20px; */
  /* position: absolute; */
  /* top: 13.563rem; */
  /* left: 1.631rem; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-dimgray);
}

.pageContainer {
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
  text-align: left;
  font-size: var(--m3-body-large-size);
  color: var(--color);
  font-family: var(--font-sarabun);
  width: 100%;
  height: 100vh;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.height100 {
  height: 75vh;
}

.theadcontain {
  background-color: #f0f0f0; /* Light gray background for thead */
  text-align: left; /* Align the text to the left */
}
/* .trheaderContent{
  border: 1px solid;
  display: flex;
  justify-content: space-between;
} */
.trheaderContent th {
  padding: 16px; /* Add padding for better spacing */
  text-align: center; /* Center-align the text */
  font-weight: bold; /* Make the header text bold */
  /* justify-content: space-between;
  border: 1px solid lightblue; */
}

.tbodycontain tr td {
  padding: 16px; /* Add padding for better spacing */
  text-align: center; /* Center-align the text */
  border-bottom: 1px solid #ddd; /* Add a bottom border for rows */
}

.table tr:hover {
  background-color: #f5f5f5; /* Add a subtle hover effect for rows */
}
.change {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 200px;
  left: 0; /* Use `left` instead of `inset-inline-start` for better browser compatibility */
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px; /* Adjust this if needed */
}

.family {
  font-family: Sarabun;
  height: 100vh;
  padding: 20px;
}
