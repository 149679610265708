.rightofsidenavcontainer {
  align-self: stretch;
  flex: 1;
  /* overflow: auto; */
  flex-direction: column;
  justify-content: flex-start;
}
.companyList,
.pagecontainer,
.rightofsidenavcontainer {
  display: flex;
  /* align-items: flex-start; */
}
.rightofsidenavcontainer {
  /* margin-top: 20px; */
  /* height: 100vh; */
  /* padding: 20px; */
  /* height: 100vh; */
}
.pagecontainer {
  width: 100%;
  /* height: 55rem; */
  /* border: 5px solid green; */
  flex-direction: row;
  justify-content: space-between;
}
.companyList {
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  flex-direction: column;
  justify-content: flex-start;
  /* border: 2px  solid red; */
}
