.errorMsg {
  color: #d32f2f;
  font-size: 0.9rem;
  margin-top: 5px;
}
.date {
  position: absolute;
  top: 16.875rem;
  left: -4.125rem;
}
.arrowNarrowLeftIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.back {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
  color: var(--color-dimgray);
  text-align: left;
}
.arrowNarrowLeftParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.escrowName {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-size: 28px;
  font-weight: 700;
}
.compName {
  font-weight: 400;
  font-size: 20px;
}
.httpslottiefilescomanimatIcon {
  width: 2.75rem;
  position: relative;
  height: 2rem;
  object-fit: cover;
}
.escrowstackPrivateLimitedWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.companyKyc,
.input {
  position: relative;
  line-height: 1.5rem;
}
.companyKyc {
  letter-spacing: 0.15px;
  font-weight: 600;
}
.input {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: var(--font-roboto);
  color: var(--color-dimgray);
  text-align: left;
  display: inline-block;
}
.companyKycParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-431xl);
}
.mobileMenu {
  align-self: stretch;
  position: relative;
}
.field,
.mobileMenu,
.mobileMenu1 {
  border: 0;
  background-color: transparent;
  font-family: var(--font-roboto);
  font-size: var(--font-size-base);
  color: var(--m3-sys-light-on-surface);
}
.mobileMenu1 {
  flex: 1;
  position: relative;
}
.field {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mobileMenu2 {
  flex: 1;
  position: relative;
  height: 3.5rem;
}
.mobileMenuGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.field1 {
  border: 1px solid var(--m3-sys-light-outline);
  outline: 0;
  font-family: var(--font-roboto);
  font-size: 16px;
  background-color: transparent;
  position: relative;
  height: 6.375rem;
  width: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-xs);
  box-sizing: border-box;
  color: var(--m3-sys-light-outline);
  padding: 20px;
}
.mobileMenu3 {
  align-self: stretch;
  position: relative;
  height: 6.375rem;
}
.mobileMenuParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-13xl);
  /* padding: 50px; */
}
.generateRequest {
  position: relative;
  /* font-size: var(--font-size-base); */
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: var(--font-sarabun);
  color: var(--color-white);
  text-align: center;
}
.loadsButtons {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-13xl);
  background-color: var(--color-darkslateblue);
  border-radius: var(--br-5xs);
  height: 2.5rem;
  /* display: flex; */
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: center; */
  box-sizing: border-box;
}
.frameGroup,
.frameParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: var(--gap-13xl); */
}
.frameGroup {
  /* width: 46rem; */
  width: 100%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  padding: var(--padding-base);
  box-sizing: border-box;
  font-size: var(--font-size-13xl);
  font-family: var(--font-source-sans-3);
}
.frameGroupOffline {
  /* width: 46rem; */
  width: 100%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  padding: var(--padding-base);
  box-sizing: border-box;
  font-size: var(--font-size-13xl);
  font-family: var(--font-source-sans-3);
}
.frameParent {
  /* position: absolute;
  top: 0;
  left: 1.569rem; */
  width: 100%;
  font-size: var(--font-size-9xl);
  color: var(--color-dimgray);
  font-family: var(--font-sarabun);
}

.pageContainer {
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  text-align: left;
  font-size: var(--table-heading-size);
  color: var(--color);
  font-family: var(--table-heading);
  padding: 20px;
}
.pageContainer,
.pageContainer:hover {
  background-color: var(--color-white);
  display: flex;
  align-self: stretch;
  width: auto;
  flex: 1;
  gap: var(--gap-0);
}
