.newModelcontainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(138, 138, 138, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
.label,
.newCompany {
  position: relative;
  font-weight: 500;
}
.newCompany {
  align-self: stretch;
  letter-spacing: 0.4px;
}
.label {
  letter-spacing: 0.5px;
  line-height: 1rem;
}
.floatingLabel {
  position: absolute;
  top: -0.312rem;
  left: -0.25rem;
  background-color: var(--m3-sys-light-surface);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}
.input1 {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
}
.cursorIcon,
.input2 {
  position: relative;
  display: none;
}
.input2 {
  width: 4.25rem;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
}
.cursorIcon {
  width: 0;
  height: 1rem;
}
.input {
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--m3-body-large-size);
  color: var(--m3-sys-light-on-surface);
}
.content,
.field,
.input {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
}
.content {
  width: calc(100% - 35px);
  right: 1.188rem;
  left: 1rem;
  border: none;
  font-size: 22px;
  /* border: 4px solid blue; */
}
.field {
  padding-left: 20px;
  font-size: 22px;
  width: 100%;
  right: 0;
  left: 0;
  border-radius: var(--br-xs);
  border: 1px solid var(--m3-sys-light-outline);
  box-sizing: border-box;
  /* border: 4px solid blue; */
}
.trailingIcon2 {
  position: absolute;
  top: calc(50% - 12px);
  right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  display: none;
}
.mobileMenu {
  align-self: stretch;
  position: relative;
  height: 9.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxArrowInDownIcon {
  width: 1.125rem;
  position: relative;
  height: 1.125rem;
  display: none;
}
.loads {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
}
.loadsButtons {
  cursor: pointer;
  width: 15rem;
  border-radius: var(--br-xs);
  border: 1px solid var(--color-darkslateblue);
  box-sizing: border-box;
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  gap: var(--gap-5xs);
  margin-right: 30px;
}
.loadsButtonsbtn {
  width: 15rem;
  height: 100%;
  /* border: none; */
  background: none;
  cursor: pointer;
}
.loadsButtons,
.loadsButtons1,
.loadsButtonsParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.loadsButtons1 {
  cursor: pointer;
  width: 15rem;
  border-radius: var(--br-xs);
  background-color: var(--color-darkslateblue);
  justify-content: center;
  padding: var(--padding-base) var(--padding-13xl);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  color: var(--color-white);
}
.loadsButtonsParent {
  justify-content: flex-start;
  gap: var(--gap-7xl);
  font-size: var(--m3-body-large-size);
  color: var(--color-darkslateblue);
  font-family: var(--font-sarabun);
}
.addCompany,
.mobileMenuParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mobileMenuParent {
  width: 31.625rem;
  justify-content: center;
  gap: var(--gap-base);
  font-size: var(--m3-label-medium-size);
  color: var(--m3-sys-light-outline);
  font-family: var(--m3-body-large);
}
.addCompany {
  /* position: absolute;
  top: 17rem;
  left: 32.313rem; */
  border-radius: var(--br-x);
  justify-content: flex-start;
  padding: var(--padding-13xl);
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslateblue);
  font-family: var(--font-sarabun);
  background-color: white;
  /* border: 1px solid red; */
  border-radius: 20px;
}
