.arrowNarrowLeftIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}

.partiesOverflow {
  height: 250px;
  overflow: auto;
}

.escrowName {
  font-size: 28px;
  color: #4c4e64;
  line-height: 24px;
  font-weight: 700;
}
.back {
  position: relative;
  letter-spacing: 0.4px;
}
.arrowNarrowLeftParent,
.frameWrapper {
  display: flex;
  justify-content: flex-start;
}
.arrowNarrowLeftParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}
.frameWrapper {
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}
.uploadTheFinal {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  /* altext: center; */
  text-align: center;
}
.uploadTheFinalDraftOfAgreParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.imagePlaceholderIcon {
  width: 10.75rem;
  position: relative;
  border-radius: 11.08px;
  height: 10.75rem;
}
.frameChild {
  width: 1.75rem;
  position: relative;
  height: 1.5rem;
}
.clickToUpload,
.dragDrop {
  font-family: var(--font-source-sans-3);
}
.dragDrop {
  color: var(--color-gray-300);
  white-space: pre-wrap;
}
.clickToUpload {
  text-decoration: underline;
  color: var(--color-darkslateblue);
}
.dragDropContainer {
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: -0.01em;
  line-height: 150%;
  text-align: left;
}
.frameContainer,
.groupParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.groupParent {
  cursor: pointer;
  border: 1px dashed var(--color-darkgray);
  padding: var(--padding-3xs);
  background-color: transparent;
  align-self: stretch;
  border-radius: var(--br-5xs);
  flex-direction: row;
  gap: var(--gap-xs);
  justify-self: center;
  /* margin-left: 80px; */
  /* border: 1px solid red; */
}
.frameContainer {
  flex-direction: column;
  padding: var(--padding-153xl) var(--padding-41xl);
  gap: var(--gap-13xl);
  /* border: 2px solid yellow; */
}
.input,
.parties {
  position: relative;
  line-height: 1.5rem;
}
.parties {
  letter-spacing: 0.15px;
  font-weight: 600;
}
.input {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: var(--font-roboto);
  color: var(--color-dimgray);
  text-align: left;
  display: inline-block;
}
.partiesParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-457xl);
  font-size: var(--font-size-13xl);
}
.mobileMenu,
.mobileMenu1 {
  border: 0;
  background-color: transparent;
  position: relative;
  font-family: var(--font-roboto);
  font-size: var(--font-size-base);
  color: var(--m3-sys-light-on-surface);
}
.mobileMenu {
  width: 19.313rem;
}
.mobileMenu1 {
  flex: 1;
}
.mobileMenuParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-start;
  gap: var(--gap-base);
}
.framehidden {
  display: none;
}
.mobileMenuParent1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frame1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.field {
  border: 0;
  background-color: transparent;
  /* position: absolute; */
  height: 100%;
  width: 100%;
  /* top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  font-family: var(--font-roboto);
  font-size: var(--font-size-base);
  color: var(--m3-sys-light-on-surface);
}
.trailingIcon2 {
  /* position: absolute; */
  top: calc(50% - 12px);
  right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  display: none;
}
.mobileMenu2,
.mobileMenu3 {
  align-self: stretch;
  width: 19.313rem;
  position: relative;
}
.mobileMenu3 {
  border: 0;
  background-color: transparent;
  font-family: var(--font-roboto);
  font-size: var(--font-size-base);
  color: var(--m3-sys-light-on-surface);
}
.frameParent1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.parties2 {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-13xl);
}
.partieslistitem {
  margin: 20px 0px;
  font-weight: 600;
}
.input1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  font-family: var(--font-roboto);
  color: var(--color-dimgray);
  text-align: left;
}
.inputParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-5xs);
}
.frameWrapper1,
.partiesGroup {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.partiesGroup {
  flex-direction: row;
  gap: var(--gap-415xl);
}
.frameWrapper1 {
  flex-direction: column;
  margin-right: 10px;
}
.boxArrowInDownIcon {
  width: 1.125rem;
  position: relative;
  height: 1.125rem;
  display: none;
}
.addDirector1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: var(--font-sarabun);
  color: var(--color-darkslateblue);
  text-align: left;
}
.addDirector {
  cursor: pointer;
  border: 1px solid var(--color-darkslateblue);
  padding: var(--padding-5xs) var(--padding-13xl);
  background-color: transparent;
  flex: 0.8294;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  margin-right: 20px;
}
.containbuttons {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.generateRequest1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: 16px;
  color: var(--color-white);
  text-align: left;
  /* border: 5px solid red; */
}
.generateRequest {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs);
  background-color: var(--color-darkslateblue);
  flex: 1;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.httpslottiefilescomanimatIcon {
  width: 2.75rem;
  position: relative;
  height: 2rem;
  object-fit: cover;
}
.addDirectorParent,
.frameDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.addDirectorParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-base);
}
.frameDiv {
  /* width: 41.625rem; */
  flex-direction: column;
  padding: var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  font-size: var(--font-size-5xl);
}
.frameGroup {
  /* align-self: stretch; */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-around;
  /* padding: var(--padding-13xl) var(--padding-base); */
  font-size: var(--font-size-sm);
  font-family: var(--font-source-sans-3);
  padding: 60px 0 60px 0;
  /* margin-bottom: 50px; */
  height: 75vh;
}
.frameParent {
  /* position: relative; */
  /* top: 0; */
  /* left: 1.006rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-dimgray);
  /* gap: var(--gap-13xl); */
  font-family: var(--font-sarabun);
  padding: 20px;
}
