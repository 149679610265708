.disabledDiv {
  pointer-events: none;
  opacity: 0.5;
}

.escrowStackPrivate {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.4px;
  font-weight: 600;
  font-family: var(--font-sarabun);
}
.companyAddress {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-13xl);
}
.requestedBy {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.cursorIcon {
  cursor: pointer;
}
.arrowNarrowLeftIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 10px;
}
.back {
  position: relative;
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
}
.requestedBy,
.expiry,
.signatureTypes,
.requested,
.signDetails {
  font-family: var(--font-sarabun);
}
.scrollableContainer {
  max-height: 800px;
  /* border: 1px solid black; */
  /* overflow-y: auto; */
}
.requestedByLabel {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.requestedByLabelParent {
  /* width: 10.438rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* gap: var(--gap-base); */
}
.digitalTechnologiesPrivate {
  flex: 1;
  position: relative;
  /* letter-spacing: 0.4px; */
  display: inline-block;
  min-width: 7.313rem;
  font-family: var(--font-sarabun);
}
.containAll {
  display: flex;
}
.maindiv {
  /* margin-top: 50px; */
  padding: 20px;
  height: 85vh;
}
.frameContainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.expiry {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.detailValues {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.emptyCells {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.detailsItems {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.signatureTypes {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
}
.detailsHeaders {
  width: 16.813rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.requested {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
  font-family: var(--font-sarabun);
}
.requestedWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div1 {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.frameDiv {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.div2 {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-family: var(--font-sarabun);
}
.downloadbtn {
  cursor: pointer;
  background: none;
  font-family: var(--font-sarabun);
  border: 2px solid #758dcb;
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #2348ab;
  font-weight: 900px;
}
.detailsHeaders1 {
  /* width: 16.75rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.signDetails {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.875rem;
  font-weight: 600;
}
.signDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-xl);
}
.nareshKumar {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 0.688rem;
  font-weight: 600;
  font-family: var(--font-sarabun);
  color: var(--color-dimgray);
}
.nareshKumarWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2.5rem 0 0;
}
.div3 {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.frameParent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.signedWithAadhaar {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-family: var(--font-sarabun);
}
.detailsHeaders2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
  font-family: var(--font-sarabun);
}
.shivakumar {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.shivakumarWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div4 {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
  font-weight: 600;
}
.frameParent2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.signedWithAadhaar1 {
  position: relative;
  letter-spacing: 0.4px;
  line-height: 0.688rem;
}
.detailsHeaders3 {
  width: 21.438rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
}
.detailsContent,
.detailsContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.detailsContent {
  align-self: stretch;
  gap: var(--gap-13xl);
  /* border: 2px solid red; */
}
.detailsContentWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  padding: var(--padding-base);
  box-sizing: border-box;
  height: 85vh;
  /* Remove any height constraints that might interfere */
}

.detailsSection {
  width: 100%;
  margin-top: 1rem; /* Replaces mt-4 */
  margin-bottom: 0.5rem; /* Replaces mb-2 */
}

.detailRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 100%; */
  margin-bottom: 0.5rem; /* Space between rows */
}

.label {
  font-weight: 600;
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
  font-size: 16px;
}

.colon {
  width: 20px;
  text-align: center;
}

.value {
  flex: 1; /* Takes remaining space */
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
}
.coloredvalue {
  flex: 1; /* Takes remaining space */
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
  color: #198754;
}
.coloredvaluefailed {
  flex: 1; /* Takes remaining space */
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
  color: #dc3545;
}
.coloredvaluepending {
  flex: 1; /* Takes remaining space */
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
  color: #e4a711;
}
.signedDetailsContainer {
  max-height: 40vh; /* 40% of viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Optional: Customize scrollbar appearance */
.signedDetailsContainer::-webkit-scrollbar {
  width: 8px;
}

.signedDetailsContainer::-webkit-scrollbar-thumb {
  background-color: #8b8b8b; /* Matches your button border color */
  border-radius: 4px;
}

.signedDetailsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}
