/* MakeEditor.module.css */
.makeEditor {
  position: fixed; /* Fixed to ensure it covers the entire screen */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
  z-index: 99;
}

.makeEditorInner {
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100; /* Ensure the inner container stays above the background */
}

.frameParent {
  justify-content: space-between;
  align-items: center;
}

.makeEditorParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.makeEditor1 {
  font-size: 24px;
  font-weight: 600;
}

.xCircleIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.tableContainer {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.MuiTableCell-root {
  padding: 12px 15px;
}

.MuiTableRow-root {
  transition: background-color 0.3s ease;
}

.MuiTableRow-root:hover {
  background-color: #f5f5f5;
}

.MuiSelect-root {
  width: 100px;
  text-align: center;
}

.actionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.actionsContainer button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.actionsContainer button:hover {
  background-color: #0056b3;
}

/* Make Table Header Sticky */
.MuiTableHead-root {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.MuiTableCell-head {
  background-color: #f1f1f1;
}
