.disabledDiv {
  pointer-events: none;
  opacity: 0.5; /* Optional: reduce opacity to visually indicate that it is disabled */
}
.compName {
  font-weight: 400;
  font-size: 20px;
}
.escrowName {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-size: 28px;
  font-weight: 700;
}

.date {
  position: absolute;
  top: 16.875rem;
  left: -4.125rem;
}
.arrowNarrowLeftIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.status {
  width: 100%;
  height: 80px;
  font-family: var(--font-sarabun);
  border: 2px dashed red;
  background-color: #fff1f3;
  display: flex;
  color: red;
  font-weight: 500;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.statusapproved {
  width: 100%;
  height: 60px;
  border: 2px dashed green;
  font-family: var(--font-sarabun);
  background-color: #f1fff8;
  display: flex;
  font-weight: 500;
  color: green;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}
.statusHeading {
  font-size: 24px;
  margin-bottom: 8px;
}
.back {
  position: relative;
  font-size: var(--m3-body-large-size);
  letter-spacing: 0.4px;
  font-family: var(--font-sarabun);
  color: var(--color-dimgray);
  text-align: left;
}
.arrowNarrowLeftParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.companyKycDetails {
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 600;
}
.companyKycDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-13xl);
}
.companyNameWrapper {
  width: 10.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.div {
  /* width: 0.313rem; */
  line-height: 2.688rem;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
}
.div,
.escrowstackPrivateLimited {
  position: relative;
  letter-spacing: 0.4px;
  margin-left: 10px;
}
.frameContainer,
.frameDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* margin-bottom: 10px; */
  /* gap: var(--gap-base); */
}
.frameContainer {
  align-self: stretch;
  border-radius: var(--br-xs);
  flex-direction: column;
  align-items: flex-start;
  /* gap: var(--gap-13xl); */
}
.companyKycResponse {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  padding: 10px 0px 10px 0;
}
.companyKycResponseWrapper {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  border-bottom: 1px solid var(--color-gray-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: var(--padding-xs) var(--padding-base); */
}
.frameParent9,
.reject {
  font-size: var(--m3-body-large-size);
}
.frameParent9 {
  width: 41.438rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: var(--gap-13xl); */
}
.reject {
  position: relative;
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: var(--font-sarabun);
  color: var(--color-white);
  text-align: left;
  font-size: 16px;
}
.loadsButtons,
.loadsButtons1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-5xs) var(--padding-13xl);
  background-color: var(--color-crimson);
  border-radius: var(--br-5xs);
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 225px;
}

.loadsButtons {
  margin-right: 16px;
  cursor: pointer;
}
.loadsButtons1 {
  cursor: pointer;
  padding: var(--padding-5xs) var(--padding-5xl);
  background-color: var(--color-seagreen);
}
.frameParent8 {
  align-self: stretch;
  font-size: var(--font-size-5xl);
}
.frameGroup,
.frameParent,
.frameParent8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: var(--gap-13xl); */
}
.frameGroup {
  align-self: stretch;
}
.frameParent {
  /* position: absolute; */
  /* top: 0; */
  /* left: 1.569rem; */
  width: 100%;
  font-size: var(--m3-body-large-size);
  color: var(--color-dimgray);
  font-family: var(--font-sarabun);
  /* padding-bottom: 30px; */
}
.pageContainer {
  height: 100%;
  /* width: 100%; */
  position: relative;
  text-align: left;
  font-size: var(--table-heading-size);
  color: var(--color);
  font-family: var(--table-heading);
  gap: var(--gap-13xl);
  /* margin-bottom: 12rem; */
  /* border: 1px solid rebeccapurple; */
}
.pageContainer,
.pageContainer:hover {
  background-color: var(--color-white);
  display: flex;
  align-self: stretch;
  width: auto;
  flex: 1;
  /* gap: var(--gap-0); */
}
.spacing {
  height: 20px;
  background-color: red;
}

.detailContainer {
  width: 100%;
  border-radius: 20px;
  background-color: #f2f2f2;
  /* padding: 20px; */
}

.detailHeader {
  /* background-color:  #f2f2f2; */
  padding: 20px;
  font-size: 24px;
  font-weight: 470;
  color: #4c4e64;
  /* width: 100%; */
  border-bottom: 1px solid #aaaaaa;
  /* border-radius: 20px; */
}

.table {
  padding: 40px;
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  background-color: white;
  padding: 20px;
}

.thead {
  background-color: #f2f2f2;
}

.tr {
  transition: background-color 0.3s;
}

.tr:hover {
  background-color: #e0e0e0;
}

.th,
.td {
  font-size: 16px;
  padding: 20px 20px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: #4c4e64;
}

.th {
  font-weight: bold;
}

.docName {
  font-size: 16px;
}

.pagination {
  padding: 20px;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}

.family {
  font-family: Sarabun;
  height: 100vh;
  padding: 20px 0px 20px 25px;
}
