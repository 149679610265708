.role {
  color: #3246a9;
  background-color: #daeeff;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
}

.mail {
  color: #747582;
  font-weight: 400;
  font-size: 18px;
}
.userName {
  font-weight: 400;
  font-size: 20px;
}

.logout {
  display: flex;
  align-items: flex-end;
}

.contractsList {
  cursor: pointer;
  border: 0;
  /* padding: var(--padding-xs); */
  background-color: transparent;
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}

.building07Icon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}

.companyList,
.kycList,
.kycList1 {
  display: flex;
  align-items: center;
}

.kycList1 {
  /* width: 100px; */
  position: relative;
  font-size: var(--m3-body-large-size);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--font-sarabun);
  color: #e53935;
  text-align: left;
  flex-shrink: 0;
}
