/* Backdrop */
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 999;
}

/* Modal Container */
.modalContainer {
  background: #fff;
  width: 350px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* Back Button */
.backButton {
  background: none;
  border: none;
  color: #2348ab;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: left;
}

/* Modal Title */
.modalTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.errorColor {
  color: #b3261e;
}
