.date {
  position: absolute;
  top: 16.875rem;
  left: -4.125rem;
}
.httpslottiefilescomanimatIcon {
  width: 18.75rem;
  position: relative;
  height: 18.75rem;
  object-fit: cover;
}
.successfullyGeneratedRequest {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.4px;
  font-weight: 600;
}
.returnToKyc {
  position: relative;
  font-size: var(--m3-body-large-size);
  letter-spacing: 0.15px;
  line-height: 1.5rem;
  font-family: var(--font-sarabun);
  color: var(--color-darkslateblue);
  text-align: left;
}
.httpslottiefilescomanimatParent,
.returnToKycList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.returnToKycList {
  cursor: pointer;
  border: 2px solid var(--color-darkslateblue);
  padding: var(--padding-5xs);
  background-color: transparent;
  border-radius: var(--br-5xs);
  flex-direction: row;
}
.httpslottiefilescomanimatParent {
  /* position: absolute;
    top: 2.75rem;
    left: 21.756rem; */
  width: 29.25rem;
  flex-direction: column;
  gap: var(--gap-21xl);
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-dimgray);
  font-family: var(--font-sarabun);
}
.pageContainer {
  align-self: stretch;
  flex: 1;
  position: relative;
  background-color: var(--color-white);
  /* overflow-y: scroll; */
  overflow-x: hidden;
  text-align: left;
  font-size: var(--table-heading-size);
  color: var(--color);
  font-family: var(--table-heading);
}
