.container {
  height: 100%;
  width: 100%;
  max-width: 100%;
  /* margin-left: 20px; */
  margin-top: 32px;
  margin-bottom: 32px;
}
.button {
  background-color: transparent;
  border: 1px solid var(--color-darkslateblue);
  padding: 10px;
  border-radius: 12px;
  color: var(--color-darkslateblue);
  font-weight: 800;
  font-family: var(--font-sarabun);
  cursor: pointer;
}
.btncontainer {
  width: 100%;
  /* padding: 20px; */
  padding-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  justify-content: space-evenly;
}
.btnc {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
